import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import searchReducer from "./features/search/searchSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    search: searchReducer,
  },
});
