import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    filters: {},
    enabled: false,
    isTyping: false,
    searchNow: false,
    text: "",
  },
  reducers: {
    searchNow: state => {
      state.searchNow = Math.random();
    },
    addFilter: (state, action) => {
      if (action.payload.key !== "") {
        if (action.payload.value !== "") {
          const tmpStore = { ...state.filters, [action.payload.key]: action.payload.value };
          state.filters = tmpStore;
        } else {
          if (state.filters.hasOwnProperty(action.payload.key)) {
            const { [action.payload.key]: _, ...remainingFilters } = state.filters;
            state.filters = remainingFilters;
          }
        }
      }
    },
    removeFilter: (state, action) => {
      if (state.filters.hasOwnProperty(action.payload)) {
        const { [action.payload]: _, ...remainingFilters } = state.filters;
        state.filters = remainingFilters;
      }
    },
    setRecord: (state, action) => {
      state.text = action.payload;
    },
    enableSearch: (state, action) => {
      state.enabled = action.payload;
    },
    setTyping: (state, action) => {
      state.isTyping = action.payload;
    },
    searchResult: (state, action) => {},
  },
});

export const { searchNow, addFilter, removeFilter, setRecord, enableSearch, searchResult, setTyping } = searchSlice.actions;
export default searchSlice.reducer;
